<template>
	<div class="setting-box">
		<div class="profile-img">
			<v-avatar size="110">
				<img :src="preview ? preview : $store.state.myInfo.thumb" />
			</v-avatar>
			<v-file-input
				v-model="thumb"
				accept="image/png, image/jpeg"
				@change="previewFile(thumb)"
				class="btn-settings"
			/>
		</div>
		<div class="profile-input">
			<v-text-field
				solo
				flat
				dense
				color="#9595B2"
				placeholder="별명을 입력해주세요."
				:hint="dupMessage"
				persistent-hint
				clear-icon="mdi-close-circle"
				clearable
				:rules="[rules.nickname]"
				v-model="nickname"
			>
			</v-text-field>
		</div>
		<div class="btn-box">
			<v-btn
				class="ma-2 btn-profile-edit"
				:loading="loading"
				color="#CAE72D"
				elevation="0"
				@click="doChange()"
				:disabled="!change || loading || !valid"
			>
				확인
				<template v-slot:loader>
					<span>수정중...</span>
				</template>
			</v-btn>
		</div>
	</div>
</template>

<script>
import { setProfile } from "@/api/member";

export default {
	name: "Profile",
	data() {
		return {
			change: false,
			thumb: null,
			preview: "",
			nickname: "",
			loading: false,
			completeMessageFlag: false,
			rules: {
				nickname: () =>
					this.valid || "최소 3자 이상 12자 이하로 입력해주세요",
			},
			valid: false,
			dupMessage: "",
		};
	},
	watch: {
		preview: function () {
			this.change =
				this.preview != "" ||
				this.$store.state.myInfo.nickname != this.nickname;
		},
		nickname: function () {
			this.change =
				this.preview != "" ||
				this.$store.state.myInfo.nickname != this.nickname;
			this.dupMessage = "";
			if (this.nickname == null || "" == this.nickname) {
				this.valid = false;
			} else {
				this.nickname = this.nickname.replace(/(\s*)/g, "");
				this.valid =
					this.nickname.length >= 3 && this.nickname.length <= 12;
			}
		},
	},
	methods: {
		async doChange() {
			this.loading = true;
			let params = {};
			if (this.$store.state.myInfo.nickname != this.nickname) {
				params["nickname"] = this.nickname;
			}
			if (this.thumb) {
				params["thumb"] = this.thumb;
			}

			const resp = await setProfile(params);
			this.loading = false;
			if (resp.result == false) {
				this.dupMessage = "동일한 닉네임이 존재합니다.";
				this.snackbar(
					"commonSnackbar",
					"동일한 닉네임이 존재합니다.",
					"info",
					56
				);
			} else {
				this.$store.state.myInfo.nickname = this.nickname;
				this.change = false;
				this.snackbar(
					"commonSnackbar",
					"프로필이 수정되었습니다",
					"check",
					56
				);
			}
		},
		previewFile(thumb) {
			if (!thumb) {
				this.preview = "";
				return;
			}
			const fileData = (data) => {
				this.preview = data;
			};
			const reader = new FileReader();
			reader.readAsDataURL(thumb);
			reader.addEventListener(
				"load",
				function () {
					fileData(reader.result);
				},
				false
			);
		},
	},
	mounted() {
		this.nickname = this.$store.state.myInfo.nickname;
	},
};
</script>

<style scoped lang="scss">
.setting-box {
	height: 90vh;
	padding-top: 24%;
	text-align: center;
	background: #fff;
	box-sizing: border-box;
	.profile-img {
		display: inline-block;
		position: relative;
	}
}
.btn-settings::v-deep {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 29px;
	height: 29px;
	padding-top: 0;
	border: 1px solid #d9d9d9;
	background-color: #fff;
	border-radius: 50%;
	.mdi-paperclip::before {
		content: "";
		width: 24px;
		height: 24px;
		background-image: url("../components/icons/settings.png");
		background-size: cover;
	}
	.v-input__control {
		display: none;
	}
	.v-input__prepend-outer {
		margin: 1px 0 0 1px;
	}
}
.profile-input::v-deep {
	width: 100%;
	margin-top: 40px;
	text-align: center;
	.txt {
		width: 200px;
		margin: 0 auto;
		font-size: 13px;
		color: #424252;
		font-weight: 600;
		text-align: left;
	}
	.v-input {
		width: 200px;
		display: inline-block;
	}
	.v-input__slot {
		padding: 0 !important;
		border-bottom: 1px solid #191923;
		border-radius: 0;
	}
	.v-input__icon {
		height: 16px;
		width: 16px;
		min-width: 16px;
	}
	.v-icon.v-icon {
		font-size: 22px;
	}
}

.btn-add-file::v-deep {
	width: 24px;
	margin-left: 8px;
	flex: 0 1 auto;
}

.custom-loader {
	animation: loader 1s infinite;
	display: flex;
}
@-moz-keyframes loader {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
@-webkit-keyframes loader {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
@-o-keyframes loader {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
@keyframes loader {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
.btn-profile-edit::v-deep {
	border-radius: 8px;
	&.v-size--default {
		width: 200px;
		height: 40px;
		font-weight: 600;
	}
	&.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
		background: rgba(149, 149, 178, 0.1) !important;
		color: #babad4 !important;
	}
}
</style>